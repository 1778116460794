//
// Base styles
//

.eshava-btn {
  display: inline-block;
  align-self: flex-start;
  padding: 0.25rem 0.5rem;
  // font-family: Arial,sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--button-color);
  background-color: var(--button-background-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid var(--button-border-color);
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  text-transform: none;
  margin: 0;

  &:hover {
    filter: brightness(90%);
  }

  &:focus-visible {
    filter: brightness(90%);
    outline: 0;
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: none;
    opacity: 0.65;
    box-shadow: none;
  }

  &.eshava-btn-primary {
    color: var(--color);
    background-color: var(--primary);
    border-color: var(--primary);
  }

  &.eshava-btn-outline-primary {
    color: var(--primary);
    background-color: var(--background-color);
    border-color: var(--primary);

    &:hover {
      color: var(--color);
      background-color: var(--primary);
      border-color: var(--primary);
      filter: brightness(100%);
    }

    &:focus-visible {
      filter: brightness(100%);
    }
  }

  &.eshava-btn-outline {
    color: var(--color);
    background-color: var(--background-color);
    border-color: var(--color);

    &:hover {
      color: var(--button-color);
      background-color: var(--button-background-color);
      border-color: var(--button-border-color);
      filter: brightness(100%);
    }

    &:focus-visible {
      filter: brightness(100%);
    }
  }
}
