.eshava-grid-container {
  font-size: 1rem;
  line-height: 2rem;

  .eshava-grid {
    .eshava-grid-body {
      .eshava-grid-row-body {
        cursor: pointer;
        .eshava-grid-cell {
          border-bottom: 0.0625rem solid var(--eshava-grid-border-color);
          border-top: 0.0625rem solid var(--eshava-grid-border-color);

          &:first-child {
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            border-left: 0.0625rem solid var(--eshava-grid-border-color);
          }

          &:last-child {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            border-right: 0.0625rem solid var(--eshava-grid-border-color);
          }

          .eshava-grid-cell-thumbnail {
            width: 2.5rem;
            height: 2.5rem;
            margin-top: -0.25rem;
          }
        }
      }
    }
  }
}

.cell-primary {
  font-size: 0.875rem;
  height: 1rem;
  line-height: 1;
  margin-bottom: 0.25rem;

  &.ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.cell-secondary {
  font-size: 0.625rem;
  height: 0.625rem;
  line-height: 1;
}
